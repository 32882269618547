// The following is to fix a known issue with Bootstrap that the top bar
// overlaps the content. The @media avoids issues on phones and smaller
// screens.
// See: http://stackoverflow.com/questions/11124777/twitter-bootstrap-navbar-fixed-top-overlapping-site
// @media (min-width: 980px) {

// It appears the rlh in Chrome is 18px and the navbar is 56px high.
$navbar-height: 3.1111rlh;

body {
  padding-top: $navbar-height; // 56px;
  padding-bottom: 42px;
  overflow-y: scroll;
}

.jumbotron {
  @extend .text-light;
}

.vertical_align_children * {
  vertical-align: middle;
}

.post-meta {
  @extend .small;
}

.post-link {
  text-decoration: none;
  color: inherit;
}

:root {
  --bs-code-color: --bs-body-color;
}

.triangle {
  height: 5rem;
  width: 5rem;
  white-space: nowrap;
  @include caret();

  &::after {
    content: '';
    white-space: nowrap;
  }
}

.sidebar {
  position: -webkit-sticky;
  position: sticky;
  top: 56px;
}

.blog-sidebar {
  font-size: 0.75rem;

  a {
    text-decoration: none;
  }

  li {
    list-style-type: none;
  }

  ul {
    padding-inline-start: 0.5rem;
  }
}

// Classes and IDs are generated by the jekyll-toc gem.
li.toc-entry {
  list-style-type: none;
}

ul#toc {
  padding-inline-start: 0.5rem;
}

// End jekyll-toc gem.

h1,
h2,
h3,
h3,
h5,
h5 {
  scroll-margin-top: $navbar-height;
}

.copy-button {
  position: absolute;
  top: 0;
  right: 0;
  --bs-btn-padding-y: .25rem;
  --bs-btn-padding-x: .25rem;
  --bs-btn-font-size: .75rem;
}

div.highlight {
  position: relative;
}

code,
pre {
  background-color: rgb(246, 248, 250);
}

pre.highlight {
  min-height: 28px;
}
