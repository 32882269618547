// All the variables are here: https://github.com/twbs/bootstrap/blob/v4-dev/scss/_variables.scss

$primary:       #54A85B;  // "Jade" colour.
// $secondary:     $gray-600;
// $success:       $green;
// $info:          $cyan;
// $warning:       $yellow;
// $danger:        $red;
// $light:         $gray-100;
// $dark:          $gray-800;

$link-colour: #54A85B !default;
$link-hover-colour: #3C7C41 !default;

// $enable-caret: false; // This turns off the caret on drop-down menus.

$font-family-base: 'Droid Sans', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$headings-font-family: 'Signika Negative', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$jumbotron-bg: $primary;

